import React, {Component} from 'react';

class Resume extends Component {
    render(){
        return(
            <div><h2>Resume Page</h2></div>
        )
    }
}

export default Resume;