import React, {Component} from 'react';

class AboutMe extends Component {
    render(){
        return(
            <div><h2>About Me Page</h2></div>
        )
    }
}

export default AboutMe;